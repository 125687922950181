import { motion } from "framer-motion";
import { getHighlightAnimation } from "../../utils/animations/globalAnims";
import "./location.scss";
import StationsSwiper from "../../components/gallery/stations/stationSwiper";
import { stationSwiper } from "../../utils/location";
import Accordion from "../../components/accordion/accordion";
import Card from "../../components/cards/card";
import { useEffect, useLayoutEffect, useState } from "react";
import HorizontalGallery from "../../components/gallery/horizontal/horizontalGallery";
import { useLocation } from "react-router-dom";

const Location = () => {

    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Check on initial render
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [accordionItems, setAccordionItems] = useState<number[]>([
        0, 1, 2, 3, 4,
    ]);

    const handleAccordion = (index: number) => {
        if (index === accordionItems[0]) {
            return;
        }
        const updatedItems = [...accordionItems];
        const [removedItem] = updatedItems.splice(index, 1);
        updatedItems.unshift(removedItem);
        setAccordionItems(updatedItems);
    };

    return (
        <>
            <div className="contain-wrapper">
                <div className="loc-one contain">
                    <div className="motion-wrapper">
                        <motion.h2
                            initial={{ y: "100%" }}
                            whileInView={{ y: 0 }}
                            transition={{ duration: 0.5, type: "spring" }}
                        >
                            PERFECT YOUR&nbsp;
                            <motion.span
                                {...getHighlightAnimation(0.2, "#CC8B86")}
                            >
                                WORK-LIFE
                            </motion.span>
                            SYMMETRY
                        </motion.h2>
                    </div>
                    <div className="copy-wrapper">
                        <h5>LOCATION</h5>
                        <p className="large">
                            Conveniently situated between King’s Cross, Euston,
                            and Camden, the building offers employees
                            unparalleled convenience and an effortless commute
                            with an abundance of opportunities for networking
                            and leisure.
                        </p>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="contain img-group">
                    <div className="loc-two">
                        <div className="lg-img"></div>
                        <div className="sm-wrapper">
                            <div className="sm-img"></div>
                            <div className="sm-img2"></div>
                        </div>
                    </div>
                    <div className="loc-two narrow">
                        <p>Regent's Park and Camden Market</p>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper beige-back">
                <div className="loc-three contain">
                    <div className="copy-section">
                        <div className="motion-wrapper">
                            <motion.h2
                                initial={{ y: "100%" }}
                                whileInView={{ y: 0 }}
                                transition={{ duration: 0.5, type: "spring" }}
                            >
                                <motion.span
                                    {...getHighlightAnimation(0.2, "#CC8B86")}
                                >
                                    STREAMLINE
                                </motion.span>
                                &nbsp; THE DAILY COMMUTE
                            </motion.h2>
                        </div>
                        <div className="copy-wrapper">
                            <p className="large">
                                With easy access to King’s Cross, Euston and
                                Camden, 101 Bayham is well connected both
                                regionally and internationally, making it a
                                convenient base for both business and employees.
                            </p>
                        </div>
                    </div>
                    <div className="animated-section">
                        <div className="venn-wrapper">
                            <div className="circle">
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ delay: 1 }}
                                    viewport={{ once: true }}
                                    className="copy-wrapper"
                                >
                                    <h3>2</h3>
                                    <h6>MINUTES WALK TO CAMDEN</h6>
                                </motion.div>
                                <img
                                    src="../images/logo-stack.svg"
                                    alt="logo"
                                />
                            </div>
                            <motion.div
                                initial={{}}
                                whileInView={{
                                    transform: "rotate(90deg)",
                                    left: `calc(40% - ${isMobile ? 175 : 175 }px)`,
                                }}
                                viewport={{ once: true }}
                                transition={{ delay: 1, duration: 0.25 }}
                                className="circle"
                            >
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ delay: 1.25 }}
                                    viewport={{ once: true }}
                                    className="copy-wrapper"
                                >
                                    <h3>14</h3>
                                    <h6>MINUTES WALK TO KING'S CROSS</h6>
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial={{}}
                                whileInView={{
                                    transform: "rotate(-90deg)",
                                    left: `calc(60% - ${isMobile ? 125 : 175 }px)`,
                                }}
                                viewport={{ once: true }}
                                transition={{ delay: 1, duration: 0.25 }}
                                className="circle"
                            >
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ delay: 1.25 }}
                                    viewport={{ once: true }}
                                    className="copy-wrapper"
                                >
                                    <h3>9</h3>
                                    <h6>MINUTES WALK TO EUSTON</h6>
                                </motion.div>
                            </motion.div>
                        </div>
                        <div className="swiper-half">
                            <StationsSwiper slides={stationSwiper} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="loc-four contain">
                    <div className="left-side">
                        <h5>position yourself successfully</h5>
                        <div className="accordion-wrapper">
                            <motion.div
                              
                                onClick={() => handleAccordion(0)}
                                className="accordion-container"
                                id="0"
                            >
                                <Accordion
                                    color={"#2A2F1E"}
                                    title={"Food & drink"}
                                    content={[
                                        "Blues Kitchen",
                                        "BrewDog Camden",
                                        "Camden Bakery",
                                        "Fabler Bakery Camden",
                                        "Lyttleton Arms",
                                        "Hache",
                                        "Panadera",
                                        "Mr Ji",
                                        "Il Sugo",
                                    ]}
                                    numbers={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                                />
                            </motion.div>
                            <motion.div
                               
                                onClick={() => handleAccordion(1)}
                                className="accordion-container"
                                id="1"
                            >
                                <Accordion
                                    color={"#cc8b86"}
                                    title={"Shopping"}
                                    content={[
                                        "Camden Market",
                                        "Coal Drops Yard",
                                        "Whole Foods",
                                        "Sainsbury's",
                                        "M & S",                                        
                                    ]}
                                    numbers={[10, 11, 12, 13, 14]}
                                />
                            </motion.div>
                            <motion.div
                               
                                onClick={() => handleAccordion(2)}
                                className="accordion-container"
                                id="2"
                            >
                                <Accordion
                                    color={"#39805a"}
                                    title={"Parks"}
                                    content={[
                                        'St Martin\'s Gardens',
                                        "Regent's Park",
                                        "Camden Highline",
                                    ]}
                                    numbers={[15, 16, 17]}
                                />
                            </motion.div>
                            <motion.div
                               
                                onClick={() => handleAccordion(3)}
                                className="accordion-container"
                                id="3"
                            >
                                <Accordion
                                    color={"#69707D"}
                                    title={"Landmarks"}
                                    content={[
                                        "KOKO",
                                        "Jazz Cafe",
                                        "Regent's Canal",
                                    ]}
                                    numbers={[18, 19, 20]}
                                />
                            </motion.div>
                            <motion.div
                                
                                onClick={() => handleAccordion(4)}
                                className="accordion-container"
                                id="4"
                            >
                                <Accordion
                                    color={"#23534e"}
                                    title={"health"}
                                    content={[
                                        "Triyoga",
                                        "FG London Tennis",
                                        "F45 Training",
                                    ]}
                                    numbers={[21, 22, 23]}
                                />
                            </motion.div>
                        </div>
                    </div>
                    <div className="map"></div>
                </div>
            </div>
            <div className="contain-wrapper pink-back">
                <div className="loc-five contain">
                    <h5>
                        SURROUND YOURSELF WITH <br /> PEOPLE YOU RESPECT
                    </h5>
                    <div className="content-wrapper">
                        <div className="circles-container">
                            <div className="circles-wrapper">
                                <div className="circle">
                                    <img
                                        src="../images/location/icons/1.svg"
                                        alt="icon"
                                    />
                                </div>
                                <div className="circle">
                                    <img
                                        src="../images/location/icons/3.svg"
                                        alt="icon"
                                    />
                                </div>
                                <div className="circle">
                                    <img
                                        src="../images/location/icons/6.svg"
                                        alt="icon"
                                    />
                                </div>
                                <div className="circle">
                                    <img
                                        src="../images/location/icons/8.svg"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                            <div className="circles-wrapper">
                                <div className="circle">
                                    <img
                                        src="../images/location/icons/7.svg"
                                        alt="icon"
                                    />
                                </div>
                                <div className="circle">
                                    <img
                                        src="../images/location/icons/4.svg"
                                        alt="icon"
                                    />
                                </div>
                                <div className="circle">
                                    <img
                                        src="../images/location/icons/2.svg"
                                        alt="icon"
                                    />
                                </div>
                                <div className="circle">
                                    <img
                                        src="../images/location/icons/5.svg"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="copy-wrapper">
                            <h5>PLUS</h5>
                            <p>Clarion House</p>
                            <p>Starlizard</p>
                            <p>Moss</p>
                            <p>Hugo Boss</p>
                            <p>HAVAS</p>
                            <p>+ MORE</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper green-back">
                <div className="loc-six contain">
                    <h5>Take A look around</h5>
                    <HorizontalGallery />
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="card-wrapper contain">
                    <Card
                        title={"A CREATIVE WORKSPACE THAT INSPIRES"}
                        highlight={5}
                        description={"building"}
                        accentColor={"#CC8B86"}
                        arrowColor={"#DFD8CA"}
                        backgroundColor={"#DFD8CA"}
                        copyColor={"#2A2F1D"}
                        link={"building"}
                        number={"01"}
                    />
                    <Card
                        title={"GET IN TOUCH TO FIND OUT MORE"}
                        highlight={1}
                        description={"contact"}
                        accentColor={"#2A2F1D"}
                        arrowColor={"#2A2F1D"}
                        backgroundColor={"#CC8B86"}
                        copyColor={"#D9CFBD"}
                        link={"contact"}
                        number={"05"}
                    />
                </div>
            </div>
        </>
    );
};

export default Location;
