export const stationSwiper = [
  {
    station: "CAMDEN TOWN",
    connections: ["#1a1a19"],
    walking: 3,
    cycling: 1,
  },
  {
    station: "CAMDEN ROAD",
    connections: ["#1a1a19"],
    walking: 7,
    cycling: 3,
  },
  {
    station: "EUSTON",
    connections: ["#f2cc18", "#eca2c5", "#6a1e55", "#4098d8", "#1a1a19"],
    walking: 18,
    cycling: 7,
  },
  {
    station: "KING's CROSS",
    connections: [
      "#f2cc18",
      "#eca2c5",
      "#00833c",
      "#6a1e55",
      "#4098d8",
      "#1a1a19",
    ],
    walking: 15,
    cycling: 7,
  },
  {
    station: "CHALK FARM",
    connections: [
      "#1a1a19"
    ],
    walking: 15,
    cycling: 7,
  },
  {
    station: "KENTISH TOWN WEST",
    connections: ["#1a1a19"],
    walking: 16,
    cycling: 8,
  }
];
